import React from 'react';

import { PAGES } from '../../utils/utils';
import Assurances from '../../components/fr/competences/assurances';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const AssurancesPage = () => (
  <Layout page={PAGES.COMPETENCES_ASSURANCES}>
    <SEO title="Assurances" />
    <Assurances />
  </Layout>
);

export default AssurancesPage;
