import React from 'react';

import CompetencesContainer, {
  COMPETENCES,
  genererFilAriane,
} from './container';

const Assurances = () => {
  return (
    <CompetencesContainer currentSection={COMPETENCES.ASSURANCES}>
      <h1>Assurances</h1>

      {genererFilAriane('assurances')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="assurances"
          src="/img/competences_sprite.jpg"
          alt="Assurances"
        />
      </div>

      <p>
        Nous sommes qualifiés pour résoudre les conflits reliés aux{' '}
        <span className="accent">réclamations d'assurances</span> qui incluent
        tout type de réclamations notamment{' '}
        <span className="accent">automobile, habitation, invalidité</span> et{' '}
        <span className="accent">vie.</span>
      </p>
    </CompetencesContainer>
  );
};

Assurances.propTypes = {};

export default Assurances;
